import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-reveal';
import rooms from '../../data/rooms_1-28-2022.json';

const points = {
    "COMMON": 1,
    "UNCOMMON": 3,
    "MODERATE": 5,
    "RARE": 10,
    "LEGENDARY": 25,
    "MYTHIC": 100
}

const RoomList = ({ assetList, filters }) => {
    const [filteredItems, setFilteredItems] = useState([]);
    const filterKeys = Object.keys(filters);

    useEffect(() => {
        if (filterKeys.length) {
            const filtered = rooms.reduce((acc, room) => {
                if (room.quantity === '1') {
                    const has = filterKeys.every(item => {
                        Object.values(room.onchain_metadata).map(key => {
                            if (Array.isArray(key)) {
                                if (key.includes(item)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                if (item === key) {
                                    acc.push(room);
                                } else {
                                    return false;
                                }
                            }
                        });
                    });
        
                    return acc;
                }

                return acc;
            }, []);

            setFilteredItems(filtered);
        } else {
            setFilteredItems([]);
        }
    }, [filters]);

    return (
        <RoomListWrapper>
            {filterKeys.length ? (
                filteredItems.length 
                    ? 
                    filteredItems.map((room, idx) => (
                        <Fade bottom key={room.name}>
                            <Room assetList={assetList} room={room} key={idx} />
                        </Fade>
                    ))
                    : (
                        <h2>No rooms found.</h2>
                    )
            ) : (
                rooms.map((room, idx) => (
                    <Fade bottom key={room.name}>
                        <Room assetList={assetList} room={room} key={idx} />
                    </Fade>                
                ))
            )}
        </RoomListWrapper>
    )
}

export default RoomList;

const RoomListWrapper = styled.main`
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    grid-gap: 20px;

    h2 {
        margin-top: 25px;
        font-size: 2rem;
        font-weight: 700;
    }

    .room-item {
        cursor: pointer;
        width: 300px;
        height: 100%;
        background-color: #232b42;
        position: relative;
        border: 3px solid #14b8a6;
        transition: all .2s;
        
        &:hover {
            transform: translateY(-2px);
        }

        .room-type {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;
            border-left: 3px solid #14b8a6;
            border-bottom: 3px solid #14b8a6;
            background-color: #232b42;
        }

        .room-score {
            position: absolute;
            top: 0;
            left: 0;
            padding: 5px;
            border-right: 3px solid #14b8a6;
            border-bottom: 3px solid #14b8a6;
            background-color: #232b42;
        }

        img {
            width: 300px;
            height: 230px;
            object-fit: cover;
            object-position: top;
        }
    }
`

const Room = ({ room, key, assetList }) => {
    const [score, setScore] = useState(0);

    const renderImageUrl = (file) => {
        let extension;
        if (file.mediaType === 'image/jpeg') {
            extension = '.jpeg';
        } else {
            extension = '.gif';
        }
        return `https://dpr445a87m8hc.cloudfront.net/${file.name}${extension}`;
    }
    

    useEffect(() => {
        const { onchain_metadata } = room;
        const newPoints = room.assets?.reduce((acc, item) => {
            const curr = assetList[item];
            if (!curr) return acc;
            const pts = points[curr.rarity];
            acc += pts;
            return acc;
        }, 0);

        setScore(newPoints);
    }, [room]);

    return (
        <div key={key} className="room-item">
            <div className="room-type">{room.onchain_metadata['--General--'][1].type}</div>
            <div className="room-score">
                {room.onchain_metadata.name}
            </div>
            <div className="room-img">
                <img src={renderImageUrl(room.onchain_metadata.files[0])} alt="room example" />
            </div>
        </div>
    )
}