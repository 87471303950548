import React from 'react';
import { useState } from 'react';
import FlipMove from 'react-flip-move';
import styled from 'styled-components';
import Layout from '../components/Layout';
import flat from 'flat';

import { FormControl, Input, IconButton, Icon, Spinner, Checkbox, Image, Badge } from '@vechaiui/react';
import { Search, ChevronDown, ChevronUp } from 'react-feather';

import assetList from '../data/output_1642024966231.json';
import RoomList from '../components/Assets/RoomList';
import ActiveFilters from '../components/Assets/ActiveFilters';

const Assets = () => {
	const [filters, setFilters] = useState({});
	const flatAssetList = flat(assetList, { maxDepth: 2 });

	const handleSetFilter = (filterItem, remove) => {
		const filterObj = {...filters};
		if (remove) {
			delete filterObj[filterItem];
		} else {
			filterObj[filterItem] = true;
		}

		setFilters(filterObj);
	}

	return (
		<Layout>
			<AssetsPage>
				<Sidebar handleSetFilter={handleSetFilter} />
				<MainArea>
					<ActiveFilters filters={filters} />
                    <RoomList filters={filters} assetList={flatAssetList} />
                </MainArea>
			</AssetsPage>
		</Layout>
	);
};

export default Assets;

const AssetsPage = styled.div`
	display: flex;
	width: 100%;
`;

const MainArea = styled.div`
	max-height: 100vh;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	padding: 0 15px;
`;

const Sidebar = ({ handleSetFilter }) => {
	return (
		<SidebarWrap>
			<FormControl className="castle-search-form" id="castleNumber">
				<Input disabled size="lg" type="number" placeholder="Room #" />
				<IconButton size="lg" variant="solid" color="primary">
					<Icon as={Search} label="search" className="w-4.5 h-4.5" />
				</IconButton>
			</FormControl>
			<AssetTypeList handleSetFilter={handleSetFilter} assets={assetList} />
		</SidebarWrap>
	);
};

const SidebarWrap = styled.nav`
	width: 425px;
	height: 100%;
	max-width: 100%;
	padding-bottom: 100px;
	background-color: #232b42;

	.castle-search-form {
		padding: 15px 10px;
		display: flex;
		align-items: center;

		button {
			cursor: pointer;
			margin-left: 15px;
		}
	}
`;

const AssetTypeList = ({ assets, handleSetFilter }) => {
	if (!assets) return <Spinner />;

	return (
		<AssetTypeWrapper>
			<div className="asset-type-holder">
				{Object.entries(assets).map(([ type, values ]) => <Asset handleSetFilter={handleSetFilter} title={type} values={values} />)}
			</div>
		</AssetTypeWrapper>
	);
};

const AssetTypeWrapper = styled.div`
	margin-top: 5px;
	overflow-y: auto;
	.asset-type-holder {
		max-height: 70vh;
	}
	.asset-type {
		height: fit-content;
		border-bottom: 4px solid #14b8a6;

		.asset-header {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;
			padding: 0 10px;
		}

		.assets-list {
			.asset-item {
				height: fit-content;
				padding: 10px;

				span {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.asset-name {
					color: white;
				}

				.rarities-type {
					display: flex;
					align-items: center;

					.right {
						margin-left: 5px;
					}
				}

				.img-container {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 105px;
					img {
						max-width: 100px;
						image-rendering: pixelated;
						object-fit: scale-down;
					}
				}
			}
		}
	}
`;

const Asset = ({ title, values, handleSetFilter }) => {
	const [ shown, setShown ] = useState(false);
	const chevronToShow = shown ? <ChevronUp /> : <ChevronDown />;

	const toggleAsset = () => {
		setShown(!shown);
	};

	const handleCheckbox = (e, asset) => {
		const checked = e?.currentTarget?.checked;

		handleSetFilter(asset.displayName, !checked);
	}

	const scores = {
		COMMON: 1,
		UNCOMMON: 2,
		MODERATE: 3,
		RARE: 4,
		LEGENDARY: 5,
		MYTHIC: 6
	}

	const genImagePath = (asset) => {
		return `/assets/${asset.fileType}/${asset.filename}`;
	}

	return (
		<div key={title} className="asset-type">
			<div onClick={toggleAsset} className="asset-header">
				<div className="asset-title">{title}</div>
				<div className="asset-chevron">{chevronToShow}</div>
			</div>
			<div className="assets-list">
				<FlipMove
                    duration={250}     
                    easing="ease-in"
                    enterAnimation = "elevator"
                    leaveAnimation = "none"
                >
					{shown &&
						Object.entries(values).sort(([xa, a], [xb, b]) => scores[a.rarity] - scores[b.rarity]).map(([key, asset]) => (
							<div className="asset-item" key={asset.name}>
								<Checkbox onChange={e => handleCheckbox(e, asset)} className="asset-item__checkbox">
									<div className="img-container">
										<img src={asset.imagePath ? asset.imagePath : genImagePath(asset)} alt={asset.name} />
									</div>
									<div className="asset-name">
										{asset.displayName ? asset.displayName : asset.name ? asset.name : key}
										<div className="rarities-type">
											<Rarity rarity={asset.rarity} />
											{asset.roomType && <RarityWrapper className='right' color="#b980c8">{asset.roomType}</RarityWrapper>}
										</div>
									</div>
								</Checkbox>
							</div>
						))}
				</FlipMove>
			</div>
		</div>
	);
};

const Rarity = ({ rarity }) => {
	const rarities = {
		COMMON: {
			color: 'gray',
			name: 'COMMON'
		},
		UNCOMMON: {
			color: '#47aa47',
			name: 'UNCOMMON'
		},
		MODERATE: {
			color: '#ababea',
			name: 'MODERATE'
		},
		RARE: {
			color: '#cc71ff',
			name: 'RARE'
		},
		LEGENDARY: {
			color: 'orange',
			name: 'LEGENDARY'
		},
		MYTHIC: {
			color: 'firebrick',
			name: 'MYTHIC'
		},
		NONE: {
			color: 'grey',
			name: "NONE"
		}
	};
	return <RarityWrapper color={rarities[rarity].color}>{rarities[rarity].name}</RarityWrapper>;
};

const RarityWrapper = styled.div`
	background-color: ${(props) => props.color};
	color: white;
	width: fit-content;
	padding: 1px 3px;
	font-size: 0.6rem;
	border-radius: 5px;
`;
