import { Badge } from '@vechaiui/react';
import React from 'react';
import styled from 'styled-components';

const ActiveFilters = ({ filters }) => {
    return (
        <ActiveFiltersWrap>
            <div className="filters-title">
                Active Filters
            </div>
            <div className="filters-list">
                {Object.keys(filters).length === 0 ? (
                    <p>None</p>
                ) : (
                    Object.keys(filters).map(filter => (
                        <div key={filter} className="filter-item">
                            <Badge>{filter}</Badge>
                        </div>
                    ))
                )}
            </div>
        </ActiveFiltersWrap>
    )
}

export default ActiveFilters;

const ActiveFiltersWrap = styled.div`
    min-height: 50px;
    .filters-title {
        font-size: 1.3rem;
        font-weight: 700;
    }

    .filters-list {
        padding: 10px 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .filter-item {
        margin-right: 5px;
        margin-bottom: 5px;
    }
`